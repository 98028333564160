.project-container{
    width: 70%;
    margin: 0 auto;
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 4rem;


    .project{
        width: 100%;
        margin-top: 7rem;

        li{
            font-size: 1.5rem;
        }
    }

    .work-exp{
        width: 100%;

        li{
            font-size: 1.5rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
        }
        p{
            font-size: 1.5rem;
        }
        h2{
            margin-bottom: 1rem;
        }
    }

    .references{
        width: 100%;
        margin-bottom: 3rem;

        li{
            font-size: 1.5rem;
        }
    }
}