*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}


.header{
    background: #422eb4;
    height: 5rem;
    width: 100%;

    .bar{
        width: 1.5rem;
        height: 1.5rem;
        background: #000;
        padding: 0.5rem;
    }
    
    .header-menu{
        width: 95%;
        margin: 0 auto;
        display: flex;
        padding-top: 1.5rem;
        font-size: large;
        gap: 2rem;


        a{
            color: #fff;
            text-decoration: none;
        }

        .active{
            color: red;
        }

       .home{
        margin-right: auto;
       }
  
    }


}


@media screen and (max-width: 672px) {
    .header{
       .bar{
        position: fixed;
        top: 2rem;
        right: 2rem;
        z-index: 99;
       }
    }
    .header-menu{
        flex-direction: column;
        position: fixed;
        right: 2rem;
        top: 2rem;
        z-index: 99;
        text-align: center;
        width: 20%;
        height: 29rem;
        border-radius: 5px;
        background: #A5B0B6;
        padding: 2rem;
        font-size: large;

    }
}