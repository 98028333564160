*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.about-container{
    width: 95%;
    margin-top: 8rem;


    

    .details{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3rem;

        .bio-data{
            width: 65rem;
            margin: 0 auto;
            display: flex;
            gap: 1rem;
    
            img{
                width: 30%;
                height: 100%;
                border-radius: 5px;
            }
    
            .bio-info{
                width: 28rem;
                padding: 7px;
                color: #fff;
                margin-top: 5rem;
            }
        }


        .education{
            width: 65%;
            margin: 2rem auto;
            opacity: 0;
            animation: fadeInLeft 1s 1s;
            animation-fill-mode: forwards;
            padding-left: 2rem;
            padding-top: 2rem;

            h1{
                font-size: 1.8rme;
                color: #fff;
            }

            h2{
                font-size: 1.7rem;
                color: #fff;
            }

            p{
                font-size: 1.5rem;
                color: #fff;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }

            .box-1{
                width: 25px;
                height: 25px;
                border-top: 4px solid #27ae60;
                border-left: 4px solid #27ae60;
                position: absolute;
                top: 0;
                left: 0;
            }
            .box-2{
                width: 25px;
                height: 25px;
                border-right: 4px solid #27ae60;
                border-bottom: 4px solid #27ae60;
                position: absolute;
                top: 90%;
                left: 99%;
            }

        }

        .skills{
            width: 65%;
            margin: 2rem auto;
            padding-left: 2rem;
            padding-top: 2rem;
            opacity: 0;
            animation: fadeInRight 1s 1s;
            animation-fill-mode: forwards;


            h1{
                font-size: 1.8rme;
                color: #fff;
            }

            p{
                font-size: 1.5rem;
                color: #fff;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }


            .box-5{
                width: 25px;
                height: 25px;
                border-top: 4px solid #27ae60;
                border-left: 4px solid #27ae60;
                position: absolute;
                top: 0;
                left: 0;
            }
            .box-6{
                width: 25px;
                height: 25px;
                border-right: 4px solid #27ae60;
                border-bottom: 4px solid #27ae60;
                position: absolute;
                top: 90%;
                left: 99%;
            }

        }

        .teaching-exp{
            width: 65%;
            margin: 2rem auto;
            padding-left: 2rem;
            padding-top: 2rem;
            opacity: 0;
            animation: fadeInUp 1s 1s;
            animation-fill-mode: forwards;


            h1{
                font-size: 1.8rme;
                color: #fff;
            }

            h2{
                font-size: 1.7rem;
                color: #fff;
            }

            li{
                font-size: 1.5rem;
                color: #fff;
                margin-top: 1rem;
                margin-bottom: 1rem;
            }


            .box-3{
                width: 25px;
                height: 25px;
                border-top: 4px solid #27ae60;
                border-left: 4px solid #27ae60;
                position: absolute;
                top: 0;
                left: 0;
            }
            .box-4{
                width: 25px;
                height: 25px;
                border-right: 4px solid #27ae60;
                border-bottom: 4px solid #27ae60;
                position: absolute;
                top: 90%;
                left: 99%;
            }
        }
    }
}

@media screen and (max-width: 672px) {
    .details{
        .bio-data{
            flex-direction: column;
        }

        .education{
            p{
                font-size: larger;
            }
        }

        .skills{
            p{
                font-size: larger;
            }
        }

        .teaching-exp{
            p{
                font-size: larger;
            }
        }
    }
}
