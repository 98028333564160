*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

.prof-container{
    width: 100%;
    // height: 100vh;
    // position: relative;
    
    
 .prof-items{
      width: 95%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 1fr;
    .box-1{
        width: 30rem;
        height: 50rem;
        background: #27ae60;
        opacity: 0;
        animation: fadeInLeft 1s 1s;
        animation-fill-mode: forwards;
        position: absolute;
        top: 13%;
        left: 3%;
    }
    .box-2{
        width: 30rem;
        height: 42rem;
        border: 2px solid blueviolet;
        opacity: 0;
        animation: fadeInDown 1s 1s;
        animation-delay: 2s;
        animation-fill-mode: forwards;
        position: absolute;
        top: 15%;
        left: 12%;
        border-radius: 5px;
    }


    .pic{
        width: 30rem;
        height: 42rem;
        margin-left: 10rem;
        margin-top: 6rem;
        // position: absolute;
        // left: 10%;
        // top: 17%;

        img{
            width: 100%;
            height: 100%;
            border-radius: 7px;
            opacity: 0;
            animation: fadeInUp 1s 1s;
            animation-delay: 2s;
            animation-fill-mode: forwards;
        }
    }

    .text-zone{
        width: 45rem;
        height: 15rem;
        margin-top: 20rem;
        // position: absolute;
        // top: 30%;
        // left: 50%;

        h1{
            text-align: center;
            font-family: 'Coolvetica';
            font-weight: 500;
            opacity: 0;
            animation: fadeInRight 1s 1s;
            animation-fill-mode: forwards;
            color: #fff;

            span{
                color: #27ae60;
            }

        }


        p{
            text-align: center;
            font-size: 1.7rem;
            font-family: 'Helvetica Neue';
            font-weight: 400;
            margin-top: 5px;
            color: #fff;
            opacity: 0;
            animation: fadeInUp 1s 1s;
            animation-delay: 2s;
            animation-fill-mode: forwards;
            text-align: justify;
        }
    }
 }
}


@media screen and (max-width: 672px) {
    .prof-container{
      .prof-items{
        grid-template-columns: 1fr;
        grid-gap: 2rem;
        .box-1{
   
            display: none;
        }
        .box-2{
            
            display: none;
        }

        .pic{
            width: 23rem;
            height: 26rem;
            margin-left: 4rem;
            margin-bottom: 3rem;

            img{
                margin-top: 4rem;
            }
        }

        .text-zone{
            margin-top: 1.5rem;
            width: 25rem;
            height: 12rem;
            margin-left: 2rem;
            
            
            p{
                font-size: 1.5rem;
                margin-bottom: 4rem;
                padding: 2rem;
            }
        }
    }
      }
}

@media screen and (max-width:550px ) {
    .text-zone{
        margin-right: 2.2rem;
    }

    p{
        font-size: 2rem;
    }
    
}